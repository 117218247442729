/*
  DOM elements
 */
const hamburger = document.getElementById("hamburger")
const navigation = document.getElementById("navigation")
const navbar = document.getElementById("navbar")

/*
  responsive mobile menu
 */
hamburger.addEventListener("click", () => {
        navigation.classList.toggle("navigation--active")
    }
)
document.addEventListener("click", (evt) => {
    let targetElement = evt.target;
    do {
        if (targetElement === navigation || targetElement === hamburger) {
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);
    // This is a click outside.
    navigation.classList.remove("navigation--active")
});
document.addEventListener('scroll', (evt) => {
    if (window.scrollY > 10) {
        navbar.classList.remove("navbar--transparent")
    } else {
        navbar.classList.add("navbar--transparent")
    }
}, true)